import { CoinsSaleOrders } from '@/staff/domain/club/CoinsSaleOrders';
import { CoinsSaleOrderUi, fromCoinsSaleOrder } from '@/staff/primary/club/coins-sale-orders-table/CoinsSaleOrder.ui';
import { emptyTokensUi, fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface CoinsSaleOrdersUi {
  coinsSaleOrders: CoinsSaleOrderUi[];
  totalTokens: TokensUi;
}

export const fromCoinsSaleOrders = (coinsSaleOrders: CoinsSaleOrders): CoinsSaleOrdersUi => ({
  coinsSaleOrders: coinsSaleOrders.orders.map(fromCoinsSaleOrder),
  totalTokens: fromTokens(coinsSaleOrders.totalTokens()),
});

export const emptyCoinsSaleOrders = (): CoinsSaleOrdersUi => ({
  coinsSaleOrders: [],
  totalTokens: emptyTokensUi(),
});
