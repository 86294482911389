import { Fiat } from '@/common/domain/token/Fiat';
import { CoinsSaleOrder } from '@/staff/domain/club/CoinsSaleOrder';
import { Coin } from '@/common/domain/token/Coin';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';

export class CoinsSaleOrders {
  constructor(
    public readonly orders: CoinsSaleOrder[],
    public readonly coin: Coin
  ) {}

  totalTokens(): Tokens {
    const coins = new Coins(
      this.orders.reduce((total, coinsSaleOrder) => total + coinsSaleOrder.coins.coins.amount, 0),
      this.coin.ticker
    );
    return Tokens.of(coins).withTokenCost(Fiat.euro(this.coin.price));
  }
}
