import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/staff/domain/exclusive/ExclusiveCategory';
import { ExclusiveToCreate } from '@/staff/domain/exclusive/ExclusiveToCreate';
import { Url } from '@/common/domain/Url';

import { Language } from '@/common/domain/Language';
import { ExclusiveToUpdate } from '@/staff/domain/exclusive/ExclusiveToUpdate';
import { Optional } from '@/common/domain/Optional';
import { Media } from '@/common/domain/Media';
import { emptyTranslations, fromTranslations, toTranslation, translationHasChanged, TranslationUi } from '@/common/primary/Translation.ui';
import { ExclusiveName } from '@/staff/domain/exclusive/ExclusiveName';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';
import { AuctionUi, fromAuction } from '@/staff/primary/exclusive/Auction.ui';

export interface ExclusiveFormUi {
  slug: string;
  imageUrl: Url;
  medias: Media[];
  priceCoinsAmount: number;
  visible: boolean;
  stock: number;
  category: ExclusiveCategory;
  auction?: AuctionUi;
  nameTranslations: TranslationUi[];
  descriptionTranslations: TranslationUi[];
}

export type ExclusiveTextFormInput = keyof Pick<ExclusiveFormUi, 'imageUrl'>;
export type ExclusiveNumberFormInput = keyof Pick<ExclusiveFormUi, 'stock' | 'priceCoinsAmount'>;

export const toExclusiveToCreate = (exclusiveForm: ExclusiveFormUi, clubSlug: ClubSlug): ExclusiveToCreate => ({
  clubSlug,
  slug: exclusiveForm.slug,
  imageUrl: exclusiveForm.imageUrl,
  medias: exclusiveForm.medias,
  priceCoinsAmount: exclusiveForm.priceCoinsAmount,
  visible: exclusiveForm.visible,
  stock: exclusiveForm.stock,
  category: exclusiveForm.category,
  nameTranslations: exclusiveForm.nameTranslations.map(toTranslation<ExclusiveName>),
  descriptionTranslations: exclusiveForm.descriptionTranslations.map(toTranslation<ExclusiveDescription>),
});

export const hasExclusiveFormChanged = (exclusiveForm: ExclusiveFormUi, comparedExclusive: ExclusiveFormUi) =>
  JSON.stringify(exclusiveForm) !== JSON.stringify(comparedExclusive);

export const toExclusiveToUpdate = (exclusiveForm: ExclusiveFormUi, exclusive: Exclusive, clubSlug: ClubSlug): ExclusiveToUpdate => ({
  clubSlug: clubSlug,
  currentSlug: exclusive.slug,
  slug: toString(exclusiveForm, exclusive, 'slug'),
  imageUrl: toString(exclusiveForm, exclusive, 'imageUrl'),
  medias: toMedias(exclusiveForm, exclusive),
  priceCoinsAmount: toNumber(exclusiveForm, exclusive.pricing.coins.amount, 'priceCoinsAmount'),
  visible: toVisible(exclusiveForm, exclusive),
  stock: toNumber(exclusiveForm, exclusive.stock, 'stock'),
  category: toString(exclusiveForm, exclusive, 'category'),
  nameTranslations: exclusiveForm.nameTranslations
    .filter(translationHasChanged(exclusive.nameTranslations!))
    .map(toTranslation<ExclusiveName>),
  descriptionTranslations: exclusiveForm.descriptionTranslations
    .filter(translationHasChanged(exclusive.descriptionTranslations!))
    .map(toTranslation<ExclusiveDescription>),
});

type UpdatableExclusiveStringField = keyof Pick<ExclusiveFormUi, 'slug' | 'imageUrl' | 'category'>;
type UpdatableExclusiveNumberField = keyof Pick<ExclusiveFormUi, 'priceCoinsAmount' | 'stock'>;

const toString = <T>(exclusiveForm: ExclusiveFormUi, exclusive: Exclusive, field: UpdatableExclusiveStringField): Optional<T> =>
  exclusiveForm[field].localeCompare(exclusive[field]) !== 0 ? Optional.of<T>(exclusiveForm[field] as T) : Optional.empty();

const toNumber = (exclusiveForm: ExclusiveFormUi, numberToCompare: number, field: UpdatableExclusiveNumberField): Optional<number> =>
  Optional.of(exclusiveForm[field]).filter(updatableField => updatableField != numberToCompare);

const toVisible = (exclusiveForm: ExclusiveFormUi, exclusive: Exclusive): Optional<boolean> =>
  Optional.of(exclusiveForm.visible).filter(visible => visible != exclusive.visible);

const toMedias = (exclusiveForm: ExclusiveFormUi, exclusive: Exclusive): Optional<Media[]> =>
  JSON.stringify(exclusiveForm.medias).localeCompare(JSON.stringify(exclusive.medias)) !== 0
    ? Optional.of(exclusiveForm.medias)
    : Optional.empty();

export const fromExclusive = (exclusive: Exclusive, languages: Language[]): ExclusiveFormUi => ({
  slug: exclusive.slug,
  imageUrl: exclusive.imageUrl,
  medias: exclusive.medias,
  priceCoinsAmount: exclusive.pricing.coins.amount,
  visible: exclusive.visible,
  stock: exclusive.stock,
  category: exclusive.category,
  auction: exclusive.auction.map(fromAuction).orElseUndefined(),
  nameTranslations: fromTranslations(languages, exclusive.nameTranslations!),
  descriptionTranslations: fromTranslations(languages, exclusive.descriptionTranslations!),
});

export const emptyExclusiveForm = (languages: string[]): ExclusiveFormUi => ({
  slug: '',
  imageUrl: '',
  medias: [],
  priceCoinsAmount: 0,
  visible: true,
  stock: 1,
  category: ExclusiveCategory.COLLECTIBLE,
  nameTranslations: emptyTranslations(languages),
  descriptionTranslations: emptyTranslations(languages),
});
