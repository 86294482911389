import { Loader } from '@/common/primary/loader/Loader';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { CoinsCreationsUi, emptyCoinsCreations, fromCoinsCreations } from '@/staff/primary/club/coins-creations-table/CoinsCreations.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusivePriceVue } from '@/staff/primary/exclusive/exclusive-price';

export default defineComponent({
  name: 'CoinsCreationTable',

  components: { ExclusivePriceVue },

  setup() {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const coinsCreations = ref(Loader.loading<CoinsCreationsUi>());
    const hasCoinsCreations = computed(() => !coinsCreations.value.isLoading() && coinsCreations.value.value().coinsCreations.length > 0);

    const club = clubRepository.getCurrentClub();

    onMounted(() => listCoinsCreations());

    const listCoinsCreations = () => {
      clubRepository
        .getCoinsCreations(club.slug, club.coin)
        .then(creations => coinsCreations.value.loaded(fromCoinsCreations(creations)))
        .catch(error => {
          coinsCreations.value.loaded(emptyCoinsCreations());
          logger.error('Failed to retrieve coinsCreations', error);
        });
    };

    return {
      coinsCreations,
      hasCoinsCreations,
      t,
    };
  },
});
