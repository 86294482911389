import { Fiat } from '@/common/domain/token/Fiat';
import { CoinsPurchase } from '@/staff/domain/wallet/CoinsPurchase';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';
import { Coin } from '@/common/domain/token/Coin';

export class CoinsPurchases {
  constructor(
    public readonly coinsPurchases: CoinsPurchase[],
    public readonly coin: Coin
  ) {}

  totalTokens(): Tokens {
    const coins = new Coins(
      this.coinsPurchases.reduce((total, coinsPurchase) => coinsPurchase.cost.coins.amount + total, 0),
      this.coin.ticker
    );
    return Tokens.of(coins).withTokenCost(Fiat.euro(this.coin.price));
  }
}
