import { Order } from '@/staff/domain/order/Order';
import { ExclusiveOrderUi, fromExclusiveOrder } from '@/staff/primary/exclusive/ExclusiveOrder.ui';
import { DateUi } from '@/common/primary/date/Date.ui';
import { OrderDeliveryStatus } from '@/staff/domain/order/OrderDeliveryStatus';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { fromOldFan, OldFanUi } from '@/staff/primary/old-fan/OldFan.ui';

export interface OrderUi {
  id: string;
  date: DateUi;
  paymentType: string;
  fan: OldFanUi;
  exclusives: ExclusiveOrderUi[];
  delivered: boolean;
  originalCost: TokensUi;
  usedCoins: TokensUi;
  finalCost: TokensUi;
  loading: boolean;
}

export const fromOrder = (order: Order): OrderUi => ({
  id: order.id,
  date: new DateUi(order.date),
  paymentType: order.paymentType,
  fan: fromOldFan(order.fan),
  exclusives: order.exclusives.map(fromExclusiveOrder),
  delivered: order.deliveryStatus === OrderDeliveryStatus.DELIVERED,
  originalCost: fromTokens(order.originalCost),
  usedCoins: fromTokens(order.usedCoins),
  finalCost: fromTokens(order.finalCost),
  loading: false,
});
