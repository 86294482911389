import { CoinsSaleOrder } from '@/staff/domain/club/CoinsSaleOrder';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { DateUi } from '@/common/primary/date/Date.ui';

export interface CoinsSaleOrderUi {
  amount: TokensUi;
  date: DateUi;
}

export const fromCoinsSaleOrder = (coinsSaleOrder: CoinsSaleOrder): CoinsSaleOrderUi => ({
  amount: fromTokens(coinsSaleOrder.coins),
  date: new DateUi(coinsSaleOrder.date),
});
