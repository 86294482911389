import { Fiat } from '@/common/domain/token/Fiat';
import { CoinsCreation } from '@/staff/domain/club/CoinsCreation';
import { Coins } from '@/common/domain/token/Coins';
import { Coin } from '@/common/domain/token/Coin';
import { Tokens } from '@/common/domain/token/Tokens';

export class CoinsCreations {
  constructor(
    public readonly creations: CoinsCreation[],
    public readonly coin: Coin
  ) {}

  totalTokens(): Tokens {
    const coins = new Coins(
      this.creations.reduce((total, coinsCreation) => coinsCreation.coins.coins.amount + total, 0),
      this.coin.ticker
    );
    return Tokens.of(coins).withTokenCost(Fiat.euro(this.coin.price));
  }
}
