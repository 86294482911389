import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { CoinsCreation } from '@/staff/domain/club/CoinsCreation';

export interface BackendCoinsCreation {
  id: string;
  coins: BackendPrice;
  date: string;
}

export const toCoinsCreation = (backendCoinsCreation: BackendCoinsCreation): CoinsCreation => ({
  id: backendCoinsCreation.id,
  coins: toTokens(backendCoinsCreation.coins),
  date: new Date(backendCoinsCreation.date),
});

export const sortCoinsCreationsByDateDesc = (coinsCreation1: CoinsCreation, coinsCreation2: CoinsCreation) =>
  coinsCreation2.date.getTime() - coinsCreation1.date.getTime();
