import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';
import { ExclusiveName } from '@/staff/domain/exclusive/ExclusiveName';
import { ExclusiveToCreate } from '@/staff/domain/exclusive/ExclusiveToCreate';
import { ExclusiveRepository } from '@/staff/domain/exclusive/ExclusiveRepository';
import { Exclusives } from '@/staff/domain/exclusive/Exclusives';
import { ExclusiveSlug } from '@/staff/domain/exclusive/ExclusiveSlug';
import { ExclusiveToUpdate } from '@/staff/domain/exclusive/ExclusiveToUpdate';
import { BackendExclusive, sortExclusiveByPriceDescThenNameAsc, toExclusive } from '@/staff/secondary/exclusive/BackendExclusive';
import { toBackendExclusiveToCreate } from '@/staff/secondary/exclusive/BackendExclusiveToCreate';
import { toBackendExclusiveToUpdate } from '@/staff/secondary/exclusive/BackendExclusiveToUpdate';
import { Coin } from '@/common/domain/token/Coin';

export class BackendExclusiveRepository implements ExclusiveRepository {
  constructor(private backendCaller: BackendCaller) {}

  getByClub(clubSlug: ClubSlug, coin: Coin): Promise<Exclusives> {
    return this.backendCaller.get<BackendExclusive[]>(`api/clubs/${clubSlug}/exclusives`).then(exclusives => {
      return new Exclusives(exclusives.data.map(toExclusive).sort(sortExclusiveByPriceDescThenNameAsc), coin);
    });
  }

  getBySlug(clubSlug: ClubSlug, slug: ExclusiveSlug): Promise<Exclusive> {
    return this.backendCaller
      .get<BackendExclusive>(`api/clubs/${clubSlug}/exclusives/${slug}`, { params: { 'with-translations': true } })
      .then(exclusives => toExclusive(exclusives.data));
  }

  update(exclusiveToUpdate: ExclusiveToUpdate): Promise<Exclusive> {
    return this.backendCaller
      .patch<BackendExclusive>(
        `api/clubs/${exclusiveToUpdate.clubSlug}/exclusives/${exclusiveToUpdate.currentSlug}`,
        toBackendExclusiveToUpdate(exclusiveToUpdate)
      )
      .then(exclusives => toExclusive(exclusives.data));
  }

  create(exclusiveToCreate: ExclusiveToCreate): Promise<Exclusive> {
    return this.backendCaller
      .post<BackendExclusive>(`api/clubs/${exclusiveToCreate.clubSlug}/exclusives`, toBackendExclusiveToCreate(exclusiveToCreate))
      .then(exclusives => toExclusive(exclusives.data));
  }

  getDescriptionSuggestion(clubSlug: ClubSlug, exclusiveName: ExclusiveName): Promise<ExclusiveDescription> {
    return this.backendCaller
      .get<string>(`api/clubs/${clubSlug}/exclusive-description-suggestion`, { params: { name: exclusiveName } })
      .then(({ data }) => data);
  }
}
