import { DateUi } from '@/common/primary/date/Date.ui';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { CoinsPurchase } from '@/staff/domain/wallet/CoinsPurchase';
import { OldFanUi, fromOldFan } from '@/staff/primary/old-fan/OldFan.ui';

export interface CoinsPurchaseUi {
  cost: TokensUi;
  fan: OldFanUi;
  date: DateUi;
}

export const fromCoinsPurchase = (coinsPurchase: CoinsPurchase): CoinsPurchaseUi => ({
  cost: fromTokens(coinsPurchase.cost),
  fan: fromOldFan(coinsPurchase.fan),
  date: new DateUi(coinsPurchase.date),
});
