import { BackendFiat, toFiat } from '@/common/secondary/token/BackendFiat';
import { PaymentTypeAvailability } from '@/staff/domain/club/PaymentTypeAvailability';
import { Coin } from '@/common/domain/token/Coin';
import { Tokens } from '@/common/domain/token/Tokens';
import { Coins } from '@/common/domain/token/Coins';

export interface BackendPaymentTypeAvailability {
  accepted: boolean;
  limit: BackendFiat;
}

export const toPaymentTypeAvailability = (
  backendPaymentTypeAvailability: BackendPaymentTypeAvailability,
  coin: Coin
): PaymentTypeAvailability => ({
  accepted: backendPaymentTypeAvailability.accepted,
  limit: Tokens.of(new Coins(backendPaymentTypeAvailability.limit.value / coin.price, coin.ticker)).withTotalCost(
    toFiat(backendPaymentTypeAvailability.limit)
  ),
});
