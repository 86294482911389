include ../../club/loading-table.mixin.pug

template(v-if='programsList.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasPrograms' data-test='no-programs') {{ t('home.programs.noPrograms') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='programs-table')
        thead
          tr
            th {{ t('home.programs.table.slug') }}
            th {{ t('home.programs.table.name') }}
            th.-shrink
        tbody
          tr(v-for='(program, index) in programsList.value()' :key='index' :data-test='`program-${index}`')
            td(:title='program.name')
              .fp-icon-with-text
                router-link.fp-link(:to='{ name: \'updateProgram\', params: { clubSlug: club.slug, programSlug: program.slug  } }')
                  span.fp-text(data-test='slug') {{ program.slug }}
            td {{ program.name }}
            td
              DropdownVue(:name='`actions-program-${program.slug}`')
                DropdownItemVue(:id='program.slug' :text='t(\'home.programs.actions.update\')' icon='edit' :routeLocation='{ name: \'updateProgram\', params: { clubSlug: club.slug, programSlug: program.slug  } }' :data-test='`edit-program-${program.slug}`')
                DropdownSeparatorVue
                DropdownItemVue(:text='t(\'home.programs.actions.see\')' icon='arrow-square-in' :href='`${club.webappUrl}/programs/${program.slug}`' :data-test='`go-to-program-${program.slug}`')
