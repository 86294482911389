import { ExclusiveToCreate } from '@/staff/domain/exclusive/ExclusiveToCreate';
import { Url } from '@/common/domain/Url';
import { BackendTranslation, fromTranslation } from '@/common/secondary/BackendTranslation';
import { ExclusiveName } from '@/staff/domain/exclusive/ExclusiveName';
import { ExclusiveDescription } from '@/staff/domain/exclusive/ExclusiveDescription';
import { BackendMedia, toBackendMedia } from '@/staff/secondary/BackendMedia';

export interface BackendExclusiveToCreate {
  slug: string;
  imageUrl: Url;
  medias: BackendMedia[];
  priceCoinsAmount: number;
  visible: boolean;
  stock: number;
  category: string;
  nameTranslations: BackendTranslation[];
  descriptionTranslations: BackendTranslation[];
}

export const toBackendExclusiveToCreate = (exclusiveToCreate: ExclusiveToCreate): BackendExclusiveToCreate => ({
  slug: exclusiveToCreate.slug,
  imageUrl: exclusiveToCreate.imageUrl,
  medias: exclusiveToCreate.medias.map(toBackendMedia),
  priceCoinsAmount: exclusiveToCreate.priceCoinsAmount,
  visible: exclusiveToCreate.visible,
  stock: exclusiveToCreate.stock,
  category: exclusiveToCreate.category,
  nameTranslations: exclusiveToCreate.nameTranslations.map(fromTranslation<ExclusiveName>),
  descriptionTranslations: exclusiveToCreate.descriptionTranslations.map(fromTranslation<ExclusiveDescription>),
});
