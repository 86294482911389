import { Loader } from '@/common/primary/loader/Loader';
import { coinsPurchaseRepositoryKey } from '@/staff/domain/wallet/CoinsPurchaseRepository';
import { CoinsPurchasesUi, emptyCoinsPurchases, fromCoinsPurchases } from '@/staff/primary/wallet/coins-purchases-table/CoinsPurchases.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusivePriceVue } from '@/staff/primary/exclusive/exclusive-price';

export default defineComponent({
  name: 'CoinsPurchasesTable',

  components: { ExclusivePriceVue },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;
    const coinsPurchaseRepository = inject(coinsPurchaseRepositoryKey)!;
    const logger = inject(loggerKey)!;

    const hasCoinsPurchase = computed(() => !coinsPurchases.value.isLoading() && coinsPurchases.value.value().coinsPurchases.length > 0);
    const coinsPurchases = ref(Loader.loading<CoinsPurchasesUi>());

    const club = clubRepository.getCurrentClub();

    onMounted(() => listCoinsPurchases());

    const listCoinsPurchases = () => {
      coinsPurchaseRepository
        .getByClub(club.slug, club.coin)
        .then(coinsPurchaseRepository => coinsPurchases.value.loaded(fromCoinsPurchases(coinsPurchaseRepository)))
        .catch(error => {
          coinsPurchases.value.loaded(emptyCoinsPurchases());
          logger.error('Failed to retrieve coins purchases', error);
        });
    };

    return {
      hasCoinsPurchase,
      coinsPurchases,
      t,
    };
  },
});
