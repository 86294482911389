template(v-if='paymentTypes.isLoading()')
  .fp-horizontal-spacing.-equivalent
    .fp-horizontal-spacing--column
      .fp-placeholder
    .fp-horizontal-spacing--column
      .fp-placeholder
template(v-else)
  .fp-horizontal-spacing.-equivalent(data-test='payment-types')
    .fp-horizontal-spacing--column
      .fp-vertical-spacing.-s-xs
        .fp-vertical-spacing--line
          .fp-text.-text-sm {{ t(paymentTypes.value().FIAT?.accepted ? 'home.paymentLimits.fiat.accepted' : 'home.paymentLimits.fiat.notAccepted') }}
        .fp-vertical-spacing--line(v-if='paymentTypes.value().FIAT?.accepted')
          span.fp-text {{ t('home.paymentLimits.remaining') }} &nbsp;
          ExclusivePriceVue(:pricing='paymentTypes.value().FIAT?.limit' data-test='payment-type-fiat')
    .fp-horizontal-spacing--column
      .fp-vertical-spacing.-s-xs
        .fp-vertical-spacing--line
          .fp-text.-text-sm {{ t(paymentTypes.value().COINS?.accepted ? 'home.paymentLimits.coins.accepted' : 'home.paymentLimits.coins.notAccepted') }}
        .fp-vertical-spacing--line(v-if='paymentTypes.value().COINS?.accepted')
          span.fp-text {{ t('home.paymentLimits.remaining') }} &nbsp;
          ExclusivePriceVue(:pricing='paymentTypes.value().COINS?.limit' data-test='payment-type-coins')
