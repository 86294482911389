import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { CoinsPurchases } from '@/staff/domain/wallet/CoinsPurchases';
import { InjectionKey } from 'vue';
import { Coin } from '@/common/domain/token/Coin';

export const coinsPurchaseRepositoryKey: InjectionKey<CoinsPurchaseRepository> = Symbol();

export interface CoinsPurchaseRepository {
  getByClub(clubSlug: ClubSlug, coin: Coin): Promise<CoinsPurchases>;
}
