import { CoinsPurchases } from '@/staff/domain/wallet/CoinsPurchases';
import { CoinsPurchaseUi, fromCoinsPurchase } from '@/staff/primary/wallet/CoinsPurchase.ui';
import { emptyTokensUi, fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface CoinsPurchasesUi {
  coinsPurchases: CoinsPurchaseUi[];
  totalTokens: TokensUi;
}

export const fromCoinsPurchases = (coinPurchase: CoinsPurchases): CoinsPurchasesUi => ({
  coinsPurchases: coinPurchase.coinsPurchases.map(fromCoinsPurchase),
  totalTokens: fromTokens(coinPurchase.totalTokens()),
});

export const emptyCoinsPurchases = (): CoinsPurchasesUi => ({
  coinsPurchases: [],
  totalTokens: emptyTokensUi(),
});
