include ../../club/loading-table.mixin.pug

template(v-if='orders.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasOrders' data-test='no-orders') {{ t('home.orders.noOrders') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center
        .fp-horizontal-spacing--column
          .fp-checkable-element
            .fp-checkable-element--checkbox
              input.fp-checkbox#delivered-checkbox(v-model='showDeliveredOrders' type='checkbox' data-test='delivered-checkbox')
            .fp-checkable-element--label
              label(for='delivered-checkbox') {{ t('home.orders.showDeliveredOrders') }}
    .fp-vertical-spacing--line
      p(v-if='!filteredOrders.length' data-test='no-filtered-orders') {{ t('home.orders.noResults') }}
      table.fp-table(data-test='kriptown-orders-table')
        thead
          tr
            th {{ t('home.table.date') }}
            th {{ t('home.table.fan') }}
            th {{ t('home.orders.table.exclusive') }}
            th {{ t('home.orders.table.paymentMethod') }}
            th {{ t('home.orders.table.totalCost') }}
            th(v-if='club.coinsEnabled' data-test='tokens-amount') {{ t('home.orders.table.tokensAmount') }}
            th {{ t('home.orders.table.deliveryStatus') }}
        tbody
          tr(v-for='(order, index) in filteredOrders' :key='index' :data-test='`order-${index}`')
            td
              code {{ order.date.toHuman() }}
            td {{ order.fan.email }}
            td(:title='order.exclusives[0].name' data-test='slug') {{ order.exclusives[0].slug }}
            td {{ order.paymentType }}
            td {{ order.exclusives[0].price.totalCost.text }}
            td(v-if='club.coinsEnabled') {{ order.exclusives[0].price.coins.text }}
            td
              i.fp-icon.fp-glyph-loader.-spin.-s18(v-if='order.loading' data-test='loading')
              input.fp-checkbox(v-else :checked='order.delivered' @click='deliver(order, $event)' type='checkbox' :disabled='order.delivered' :title='t(order.delivered ? "home.orders.delivered": "home.orders.deliver")' data-test='delivery-status')
    .fp-vertical-spacing--line
      .fp-alert.-info
        i.fp-icon.fp-glyph-info.-color-info.-s18
        .fp-text
          ul
            li(data-test='total-tokens-cost')
              span.fp-text {{ t('home.orders.totalTokensCost') }} &nbsp;
              ExclusivePriceVue(:pricing='orders.value().totalCoinsTokens')
            li(data-test='total-fiat-cost')
              span.fp-text {{ t('home.orders.totalFiatCost') }} &nbsp;
              ExclusivePriceVue(:pricing='orders.value().totalFiatTokens')
          span.fp-text {{ t('home.orders.totalAmount') }} &nbsp;
            ExclusivePriceVue(:pricing='orders.value().totalTokens' data-test='total-orders')
