import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { CoinsSaleOrder } from '@/staff/domain/club/CoinsSaleOrder';

export interface BackendCoinsSaleOrder {
  id: string;
  coins: BackendPrice;
  date: string;
}

export const toCoinsSaleOrder = (backendCoinsSaleOrders: BackendCoinsSaleOrder): CoinsSaleOrder => ({
  id: backendCoinsSaleOrders.id,
  coins: toTokens(backendCoinsSaleOrders.coins),
  date: new Date(backendCoinsSaleOrders.date),
});

export const sortCoinsSaleOrdersByDateDesc = (coinsSaleOrder1: CoinsSaleOrder, coinsSaleOrder2: CoinsSaleOrder) =>
  coinsSaleOrder2.date.getTime() - coinsSaleOrder1.date.getTime();
