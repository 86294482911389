include ../../../../common/primary/select/select.mixin.pug

form.fp-card.-highlighted(autocomplete='on' @submit.prevent='confirm' :draggable='draggable' @dragstart='onDragStart' @drop.prevent='onDrop' @dragover.prevent='onDragOver' data-test='exclusive.form')
  .fp-vertical-spacing
    .fp-vertical-spacing--line(v-for='nameTranslation in exclusiveForm.nameTranslations' :key='nameTranslation.language')
      label.fp-field
        .fp-horizontal-spacing.-items-center.-s-sm
          .fp-horizontal-spacing--column
            | {{ t('exclusiveForm.name') }}
          .fp-horizontal-spacing--column
            img.fp-illustration.-xs(:class='`-flag-${nameTranslation.language}`' :data-test='`name-flag-${nameTranslation.language}`')
        .fp-horizontal-spacing--column
          .fp-field--actions
            i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetNameTranslation(nameTranslation.language)' :class='hasNameTranslationChanged(nameTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-name-${nameTranslation.language}`')
          input.fp-input-text(v-model='nameTranslation.value' @mousedown='onInputMouseDown' @mouseup='onInputMouseUp' required @focusout='onNameFocusOut(nameTranslation)' :class='{"-touched": isUpdating && hasNameTranslationChanged(nameTranslation)}' :data-test='`name-${nameTranslation.language}`')
    .fp-vertical-spacing--line
      .fp-horizontal-spacing
        .fp-horizontal-spacing--column
          label.fp-field(data-test='slug-label')
            | {{ t(`exclusiveForm.slugOn${isUpdating ? 'Update' : 'Create'}`) }}
            .fp-field--actions
              i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetTextField("slug")' :class='hasFieldChanged("slug") ? "-pointer" : "-color-shade-400"' data-test='reset-slug')
            input.fp-input-text(v-model='exclusiveForm.slug' @mousedown='onInputMouseDown' @mouseup='onInputMouseUp' pattern='[a-z0-9]+(?:-[a-z0-9]+)*' required :class='{"-touched": isUpdating && hasFieldChanged("slug")}' data-test='slug')
        .fp-horizontal-spacing--column.-expand
          .fp-vertical-spacing.-s-sm
            .fp-vertical-spacing--line
              label.fp-field
                | {{ t('exclusiveForm.visibility') }}
            .fp-vertical-spacing--line
              .fp-checkable-element
                .fp-checkable-element--checkbox
                  input.fp-checkbox#visible-checkbox(type='checkbox' v-model='exclusiveForm.visible' :class='{"-touched": isUpdating && hasFieldChanged("visible")}' data-test='visible')
                .fp-checkable-element--label
                  label(for='visible-checkbox') {{ t('exclusiveForm.visible') }}
        .fp-horizontal-spacing--column
          i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetVisibleField' :class='hasFieldChanged("visible") ? "-pointer" : "-color-shade-400"' data-test='reset-visible')
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-equivalent
        .fp-horizontal-spacing--column(data-test='main-image')
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line
              .fp-horizontal-spacing
                .fp-horizontal-spacing--column.-expand
                  label.fp-field(data-test='image-input') {{ t('exclusiveForm.mainImage') }}
                .fp-horizontal-spacing--column
                  i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetImageUrl' :class='hasFieldChanged("imageUrl") ? "-pointer" : "-color-shade-400"' data-test='reset-image')
            .fp-vertical-spacing--line
              SingleImageInputVue(:media='mainImage' :touched='isUpdating && hasFieldChanged("imageUrl")' :required='!isUpdating' @updated-media='media => updateImage(media.value)')
        .fp-horizontal-spacing--column(data-test='extra-medias')
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line
              .fp-horizontal-spacing
                .fp-horizontal-spacing--column.-expand
                  label.fp-field(data-test='medias-input') {{ t('exclusiveForm.extraMedia') }}
                .fp-horizontal-spacing--column
                  i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetMedias' :class='hasMediasChanged() ? "-pointer" : "-color-shade-400"' data-test='reset-medias')
            .fp-vertical-spacing--line
              MultipleMediasInputVue(:medias='exclusiveForm.medias' :touched='isUpdating && hasMediasChanged()' @updated-medias='medias => updateMedias(medias.value)')
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-equivalent
        .fp-horizontal-spacing--column
          .fp-vertical-spacing.-s-xs
            .fp-vertical-spacing--line
              label.fp-field(data-test='price-label')
                | {{ t(`exclusiveForm.${exclusiveForm.auction ? 'startingPrice' : 'price'}`) }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetNumberField("priceCoinsAmount")' :class='hasFieldChanged("priceCoinsAmount") ? "-pointer" : "-color-shade-400"' data-test='reset-price')
            .fp-vertical-spacing--line
              ConverterVue(:fiat-only='!club.coinsEnabled' :coins-amount='exclusiveForm.priceCoinsAmount' :coin='club.coin' :is-updating='isUpdating' @update-coins-amount='(event) => updateCoinsAmount(event.value)')
        .fp-horizontal-spacing--column
          .fp-vertical-spacing
            .fp-vertical-spacing--line
              label.fp-field
                | {{ t('exclusiveForm.category') }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetCategoryField' :class='hasFieldChanged("category") ? "-pointer" : "-color-shade-400"' data-test='reset-category')
                +fp-select
                  select(v-model='exclusiveForm.category' name='category' :class='{"-touched": isUpdating && hasFieldChanged("category")}' data-test='category')
                    option(v-for='(category, index) in categories' :key='index' :value='category' v-text='category')
            .fp-vertical-spacing--line(v-if='!exclusiveForm.auction')
              label.fp-field
                | {{ t('exclusiveForm.stock') }}
                .fp-field--actions
                  i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetNumberField("stock")' :class='hasFieldChanged("stock") ? "-pointer" : "-color-shade-400"' data-test='reset-stock')
                input.fp-input-text(v-model='exclusiveForm.stock' @mousedown='onInputMouseDown' @mouseup='onInputMouseUp' data-test='stock' type='number' step='1' :class='{"-touched": isUpdating && hasFieldChanged("stock")}' required)
    .fp-vertical-spacing--line(v-for='descriptionTranslation in exclusiveForm.descriptionTranslations' :key='descriptionTranslation.language')
      .fp-horizontal-spacing.-equivalent
        .fp-horizontal-spacing--column
          .fp-vertical-spacing.-s-sm
            .fp-vertical-spacing--line
              label.fp-field
                .fp-horizontal-spacing.-items-center.-s-sm
                  .fp-horizontal-spacing--column
                    | {{ t('exclusiveForm.description') }}
                  .fp-horizontal-spacing--column
                    img.fp-illustration.-xs(:class='`-flag-${descriptionTranslation.language}`' :data-test='`description-flag-${descriptionTranslation.language}`')
                  .fp-field--actions
                    template(v-if='descriptionTranslation.language === "fr" && suggestionAsked')
                      i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isSuggestionLoading')
                      i.fp-icon.fp-glyph-magic-wand.-pointer(v-else @click='replaceDescriptionWithSuggestion(descriptionTranslation.language)' :data-test='`apply-suggestion-${descriptionTranslation.language}`')
                    template(v-if='descriptionTranslation.language !== "fr"')
                      i.fp-icon.fp-glyph-loader.-spin.-s16(v-if='isTranslationLoading')
                      i.fp-icon.fp-glyph-translate(v-else @click='translateText(descriptionTranslation.language)' :class='hasDescription ? "-pointer" : "-color-shade-400"' :title='t(\'exclusiveForm.translate\')' :data-test='`translate-description-${descriptionTranslation.language}`')
                    i.fp-icon.-pointer(@click='togglePreviewFor(descriptionTranslation)' :class='previewVisibleFor(descriptionTranslation) ? "fp-glyph-eye-slash" : "fp-glyph-eye"' :data-test='`toggle-preview-${descriptionTranslation.language}`')
                    i.fp-icon.fp-glyph-arrow-clockwise(v-if='isUpdating' @click='resetDescriptionTranslation(descriptionTranslation.language)' :class='hasDescriptionTranslationChanged(descriptionTranslation) ? "-pointer" : "-color-shade-400"' :data-test='`reset-description-${descriptionTranslation.language}`')
            .fp-vertical-spacing--line
              MarkdownEditorVue(:text='descriptionTranslation' @updated-value='text => updateTranslation(descriptionTranslation, text.value)' :data-test='`description-${descriptionTranslation.language}`' :touched='isUpdating && hasDescriptionTranslationChanged(descriptionTranslation)' :disabled='isTranslationLoading')
        .fp-horizontal-spacing--column(v-if='previewVisibleFor(descriptionTranslation)')
          .fp-vertical-spacing.-s-sm
            .fp-vertical-spacing--line
              .fp-text.-text-semi-bold {{ t('exclusiveForm.preview') }}
            .fp-vertical-spacing--line
              .fp-text.-text-sm(v-html='formatDescription(descriptionTranslation.value)' :data-test='`preview-description-${descriptionTranslation.language}`')
