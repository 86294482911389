include ../loading-table.mixin.pug

template(v-if='coinsSaleOrders.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasCoinsSaleOrders' data-test='no-coins-sale-orders') {{ t('home.coinsSaleOrders.noCoinsSaleOrders') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='coins-sale-orders-table')
        thead
          tr
            th {{ t('home.table.date') }}
            th {{ t('home.coinsSaleOrders.table.totalCost') }}
            th {{ t('home.coinsSaleOrders.table.tokensAmount') }}
        tbody
          tr(v-for='(coinsSaleOrder, index) in coinsSaleOrders.value().coinsSaleOrders' :key='index' :data-test='`coins-sale-order-${index}`')
            td
              code {{ coinsSaleOrder.date.toHuman() }}
            td {{ coinsSaleOrder.amount.totalCost.text }}
            td {{ coinsSaleOrder.amount.coins.text }}
    .fp-vertical-spacing--line
      .fp-alert.-info
        i.fp-icon.fp-glyph-info.-color-info.-s18
        span.fp-text {{ t('home.coinsSaleOrders.totalAmount') }}
        ExclusivePriceVue(:pricing='coinsSaleOrders.value().totalTokens' data-test='total-coins-sale-orders')
