import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { Exclusive } from '@/staff/domain/exclusive/Exclusive';
import { ExclusiveCategory } from '@/staff/domain/exclusive/ExclusiveCategory';
import { fromTranslation, TranslationUi } from '@/common/primary/Translation.ui';

export interface ExclusiveUi {
  name: string;
  imageUrl: string;
  description: string;
  price: TokensUi;
  visible: boolean;
  stock: number;
  category: ExclusiveCategory;
  slug: string;
  nameTranslations?: TranslationUi[];
  descriptionTranslations?: TranslationUi[];
}

export const fromExclusive = (exclusive: Exclusive): ExclusiveUi => ({
  name: exclusive.name,
  imageUrl: exclusive.imageUrl,
  description: exclusive.description,
  price: fromTokens(exclusive.pricing),
  visible: exclusive.visible,
  stock: exclusive.stock,
  category: exclusive.category,
  slug: exclusive.slug,
  nameTranslations: exclusive.nameTranslations?.map(fromTranslation),
  descriptionTranslations: exclusive.descriptionTranslations?.map(fromTranslation),
});
