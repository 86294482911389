import { Auction } from '@/staff/domain/exclusive/Auction';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { BidUi, fromBid } from '@/staff/primary/exclusive/Bid.ui';

export interface AuctionUi {
  id: string;
  startingPrice: TokensUi;
  currentPrice: TokensUi;
  startDate: Date;
  endDate: Date;
  bids: BidUi[];
}

export const fromAuction = (auction: Auction): AuctionUi => ({
  id: auction.id,
  startingPrice: fromTokens(auction.startingPrice),
  currentPrice: fromTokens(auction.currentPrice),
  startDate: auction.startDate,
  endDate: auction.endDate,
  bids: auction.bids.map(fromBid),
});
