include ../../club/loading-table.mixin.pug

template(v-if='exclusives.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasExclusives' data-test='no-exclusives') {{ t('home.exclusives.noExclusives') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      .fp-horizontal-spacing.-items-center
        .fp-horizontal-spacing--column.-expand
          input.fp-input-text(v-model='terms' :placeholder='t(\'home.exclusives.search\')' data-test='terms-input')
        .fp-horizontal-spacing--column
          .fp-checkable-element
            .fp-checkable-element--checkbox
              input.fp-checkbox#visible-checkbox(v-model='showNotVisibleExclusives' type='checkbox' data-test='visibility-checkbox')
            .fp-checkable-element--label
              label(for='visible-checkbox') {{ t('home.exclusives.showNotVisibleExclusives') }}
    .fp-vertical-spacing--line
      p(v-if='!filteredExclusives.length' data-test='no-filtered-exclusives') {{ t('home.exclusives.noResults') }}
      table.fp-table.-with-actions(v-if='filteredExclusives.length' data-test='exclusives-table')
        thead
          tr
            th {{ t('home.exclusives.table.slug') }}
            th {{ t('home.exclusives.table.stock') }}
            th {{ t('home.exclusives.table.category') }}
            th {{ t('home.exclusives.table.totalCost') }}
            th(v-if='club.coinsEnabled' data-test='tokens-amount') {{ t('home.exclusives.table.tokensAmount') }}
            th.-shrink
        tbody
          tr(v-for='(exclusive, index) in filteredExclusives' :key='index' :data-test='`exclusive-${index}`')
            td(:title='exclusive.name')
              .fp-icon-with-text
                router-link.fp-link(:to='{ name: \'updateExclusive\', params: { clubSlug: club.slug, exclusiveSlug: exclusive.slug  } }')
                  span.fp-text(data-test='slug') {{ exclusive.slug }}
                i.fp-icon.fp-glyph-eye-slash.-s18.-color-shade-400(v-if='!exclusive.visible' data-test='not-visible-icon')
            td {{ exclusive.stock }}
            td
              span.fp-tag
                span.fp-tag--text {{ exclusive.category }}
            td {{ exclusive.price.totalCost.text }}
            td(v-if='club.coinsEnabled') {{ exclusive.price.coins.text }}
            td
              DropdownVue(:name='`actions-exclusive-${exclusive.slug}`')
                DropdownItemVue(:id='exclusive.slug' :text='t(\'home.exclusives.actions.update\')' icon='edit' :routeLocation='{ name: \'updateExclusive\', params: { clubSlug: club.slug, exclusiveSlug: exclusive.slug  } }' :data-test='`edit-exclusive-${exclusive.slug}`')
                DropdownItemVue(:text='t(\'home.exclusives.actions.clone\')' icon='copy' :routeLocation='{ name: \'createExclusive\', params: { clubSlug: club.slug }, query: { from: `${club.slug}/${exclusive.slug}`} }' :data-test='`clone-exclusive-${exclusive.slug}`')
                DropdownSeparatorVue
                DropdownItemVue(:text='t(\'home.exclusives.actions.see\')' icon='arrow-square-in' :href='`${club.webappUrl}/exclusives/${exclusive.slug}`' :data-test='`go-to-exclusive-${exclusive.slug}`')
    .fp-vertical-spacing--line
      .fp-alert.-info
        i.fp-icon.fp-glyph-info.-color-info.-s18
        ul
          li
            span.fp-text {{ t('home.exclusives.visibleTotalAmount') }} &nbsp;
            ExclusivePriceVue(:pricing='exclusives.value().visibleTotalTokens' data-test='visible-total-exclusives')
          li
            span.fp-text {{ t('home.exclusives.totalAmount') }} &nbsp;
            ExclusivePriceVue(:pricing='exclusives.value().totalTokens' data-test='total-exclusives')
