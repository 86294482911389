import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { PaymentType } from '@/staff/domain/club/PaymentType';
import { fromPaymentTypeAvailability, paymentTypeUnavailable } from '@/staff/primary/club/payment-type-limit/PaymentTypeAvailability.ui';
import { PaymentTypesLimitUi } from '@/staff/primary/club/payment-type-limit/PaymentTypesLimit';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { Loader } from '@/common/primary/loader/Loader';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusivePriceVue } from '@/staff/primary/exclusive/exclusive-price';

export default defineComponent({
  name: 'PaymentTypesLimit',

  components: { ExclusivePriceVue },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const paymentTypes = ref(Loader.loading<PaymentTypesLimitUi>());

    onMounted(() => getPaymentTypes());

    const getPaymentTypes = async () => {
      const clubCoin = clubRepository.getCurrentClub().coin;
      try {
        const [paymentTypeCoins, paymentTypeFiat] = await Promise.all([
          clubRepository.getPaymentTypes(props.clubSlug, PaymentType.COINS, clubCoin),
          clubRepository.getPaymentTypes(props.clubSlug, PaymentType.FIAT, clubCoin),
        ]);
        paymentTypes.value.loaded({
          [PaymentType.COINS]: fromPaymentTypeAvailability(paymentTypeCoins),
          [PaymentType.FIAT]: fromPaymentTypeAvailability(paymentTypeFiat),
        });
      } catch (error: any) {
        paymentTypes.value.loaded({
          [PaymentType.COINS]: paymentTypeUnavailable(),
          [PaymentType.FIAT]: paymentTypeUnavailable(),
        });
        logger.error('Failed to retrieve payment type', error);
      }
    };

    return {
      paymentTypes,
      t,
    };
  },
});
