import { BackendCaller } from '@/common/secondary/BackendCaller';
import { Club } from '@/staff/domain/club/Club';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { ClubSummary } from '@/staff/domain/club/ClubSummary';
import { CoinsSaleOrders } from '@/staff/domain/club/CoinsSaleOrders';
import { PaymentType } from '@/staff/domain/club/PaymentType';
import { PaymentTypeAvailability } from '@/staff/domain/club/PaymentTypeAvailability';
import { BackendClub, toClub } from '@/staff/secondary/club/BackendClub';
import { BackendClubSummary, toClubSummary } from '@/staff/secondary/club/BackendClubSummary';
import { BackendCoinsSaleOrder, sortCoinsSaleOrdersByDateDesc, toCoinsSaleOrder } from '@/staff/secondary/club/BackendCoinsSaleOrder';
import { BackendPaymentTypeAvailability, toPaymentTypeAvailability } from '@/staff/secondary/club/BackendPaymentTypeAvailability';
import { BackendCoinsGift, sortCoinsGiftsByDateDesc, toCoinsGift } from '@/staff/secondary/club/BackendCoinsGift';
import { CoinsGifts } from '@/staff/domain/club/CoinsGifts';
import { CoinsCreations } from '@/staff/domain/club/CoinsCreations';
import { BackendCoinsCreation, sortCoinsCreationsByDateDesc, toCoinsCreation } from '@/staff/secondary/club/BackendCoinsCreation';
import { Giveaway } from '@/staff/domain/club/giveaway/Giveaway';
import { BackendGiveaway, sortGiveawaysByDateDesc, toGiveaway } from '@/staff/secondary/club/giveaway/BackendGiveaway';
import { Coin } from '@/common/domain/token/Coin';
import { BackendFansStatistics, toFansStatistics } from '@/staff/secondary/club/BackendFansStatistics';
import { FansStatistics } from '@/staff/domain/club/FansStatistics';
import { CoinsHoldings } from '@/staff/domain/club/CoinsHoldings';
import { BackendCoinsHolding, sortCoinsHoldingsByFanEmail, toCoinsHolding } from '@/staff/secondary/club/BackendCoinsHolding';
import { toBackendGiveawayToCreate } from '@/staff/secondary/club/giveaway/BackendGiveawayToCreate';
import { GiveawayToCreate } from '@/staff/domain/club/giveaway/GiveawayToCreate';
import { GiveawayToUpdate } from '@/staff/domain/club/giveaway/GiveawayToUpdate';
import { toBackendGiveawayToUpdate } from '@/staff/secondary/club/giveaway/BackendGiveawayToUpdate';
import { GiveawayId } from '@/staff/domain/club/giveaway/GiveawayId';
import { GiveawayClosureToCreate } from '@/staff/domain/club/giveaway/GiveawayClosureToCreate';
import { toBackendGiveawayClosureToCreate } from '@/staff/secondary/club/giveaway/BackendGiveawayClosureToCreate';
import { CoinsGiftToCreate } from '@/staff/domain/club/CoinsGiftToCreate';
import { toBackendCoinsGiftToCreate } from '@/staff/secondary/club/BackendCoinsGiftToCreate';
import { PresignedUpload } from '@/staff/domain/upload/PresignedUpload';
import { FileContentType } from '@/common/domain/FileContentType';

export class BackendClubRepository {
  constructor(private backendCaller: BackendCaller) {}

  list(): Promise<ClubSummary[]> {
    return this.backendCaller.get<BackendClubSummary[]>('api/clubs').then(clubs => clubs.data.map(toClubSummary));
  }

  getBySlug(clubSlug: ClubSlug): Promise<Club> {
    return this.backendCaller.get<BackendClub>(`api/clubs/${clubSlug}`).then(clubs => toClub(clubs.data));
  }

  getCoinsCreations(clubSlug: ClubSlug, coin: Coin): Promise<CoinsCreations> {
    return this.backendCaller
      .get<BackendCoinsCreation[]>(`api/clubs/${clubSlug}/coins-creations`)
      .then(coinsCreations => new CoinsCreations(coinsCreations.data.map(toCoinsCreation).sort(sortCoinsCreationsByDateDesc), coin));
  }

  getCoinsSaleOrders(clubSlug: ClubSlug, coin: Coin): Promise<CoinsSaleOrders> {
    return this.backendCaller
      .get<BackendCoinsSaleOrder[]>(`api/clubs/${clubSlug}/coins-sale-orders`)
      .then(coinsSaleOrders => new CoinsSaleOrders(coinsSaleOrders.data.map(toCoinsSaleOrder).sort(sortCoinsSaleOrdersByDateDesc), coin));
  }

  getCoinsGifts(clubSlug: ClubSlug, coin: Coin): Promise<CoinsGifts> {
    return this.backendCaller
      .get<BackendCoinsGift[]>(`api/clubs/${clubSlug}/coins-gifts`)
      .then(coinsGifts => new CoinsGifts(coinsGifts.data.map(toCoinsGift).sort(sortCoinsGiftsByDateDesc), coin));
  }

  async createCoinsGift(clubSlug: ClubSlug, coinsGift: CoinsGiftToCreate): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/coins-gifts`, toBackendCoinsGiftToCreate(coinsGift));
  }

  getCoinsHoldings(clubSlug: ClubSlug, coin: Coin): Promise<CoinsHoldings> {
    return this.backendCaller
      .get<BackendCoinsHolding[]>(`api/clubs/${clubSlug}/coins-holdings`)
      .then(coinsHoldings => new CoinsHoldings(coinsHoldings.data.map(toCoinsHolding).sort(sortCoinsHoldingsByFanEmail), coin));
  }

  getPaymentTypes(clubSlug: ClubSlug, paymentType: PaymentType, coin: Coin): Promise<PaymentTypeAvailability> {
    return this.backendCaller
      .get<BackendPaymentTypeAvailability>(`api/clubs/${clubSlug}/payment-types/${paymentType}`)
      .then(paymentType => toPaymentTypeAvailability(paymentType.data, coin));
  }

  async createGiveaway(clubSlug: ClubSlug, giveawayToCreate: GiveawayToCreate): Promise<void> {
    await this.backendCaller.post<void>(`api/clubs/${clubSlug}/giveaways`, toBackendGiveawayToCreate(giveawayToCreate));
  }

  getGiveaways(clubSlug: ClubSlug): Promise<Giveaway[]> {
    return this.backendCaller
      .get<BackendGiveaway[]>(`api/clubs/${clubSlug}/giveaways`)
      .then(giveaways => giveaways.data.map(toGiveaway).sort(sortGiveawaysByDateDesc));
  }

  getGiveaway(clubSlug: ClubSlug, giveawayId: GiveawayId): Promise<Giveaway> {
    return this.backendCaller
      .get<BackendGiveaway>(`api/clubs/${clubSlug}/giveaways/${giveawayId}`)
      .then(giveaway => toGiveaway(giveaway.data));
  }

  updateGiveaway(giveawayToUpdate: GiveawayToUpdate): Promise<Giveaway> {
    return this.backendCaller
      .patch<BackendGiveaway>(
        `api/clubs/${giveawayToUpdate.clubSlug}/giveaways/${giveawayToUpdate.id}`,
        toBackendGiveawayToUpdate(giveawayToUpdate)
      )
      .then(({ data }) => toGiveaway(data));
  }

  async closeGiveaway(closureToCreate: GiveawayClosureToCreate): Promise<void> {
    await this.backendCaller.put<BackendGiveaway>(
      `api/clubs/${closureToCreate.clubSlug}/giveaways/${closureToCreate.giveawayId}/closure`,
      toBackendGiveawayClosureToCreate(closureToCreate)
    );
  }

  getFansStatistics(clubSlug: ClubSlug): Promise<FansStatistics> {
    return this.backendCaller
      .get<BackendFansStatistics>(`api/clubs/${clubSlug}/fans-statistics`)
      .then(fansStatistics => toFansStatistics(fansStatistics.data));
  }

  createPresignedUpload(clubSlug: ClubSlug, contentType: FileContentType): Promise<PresignedUpload> {
    return this.backendCaller.post<PresignedUpload>(`api/clubs/${clubSlug}/presigned-uploads`, { contentType }).then(({ data }) => data);
  }

  async translateText(clubSlug: ClubSlug, text: String, sourceLanguage: String, targetLanguage: String): Promise<string> {
    return await this.backendCaller
      .get<string>(`api/clubs/${clubSlug}/translation`, {
        params: { text, 'source-language': sourceLanguage, 'target-language': targetLanguage },
      })
      .then(({ data }) => data);
  }
}
