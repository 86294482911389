include ../../club/loading-table.mixin.pug

template(v-if='giveaways.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasGiveaways' data-test='no-giveaways') {{ t('home.giveaways.noGiveaways') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table.-with-actions(data-test='giveaways-table')
        thead
          tr
            th {{ t('home.giveaways.table.status.title') }}
            th {{ t('home.giveaways.table.startDate') }}
            th {{ t('home.giveaways.table.endDate') }}
            th {{ t('home.giveaways.table.questionNumber') }}
            th {{ t('home.giveaways.table.participationNumber') }}
            th {{ t('home.giveaways.table.partner') }}
            th.-shrink
        tbody
          tr(v-for='(giveaway, index) in giveaways.value()' :key='index' :data-test='`giveaway-${index}`')
            td
              span.fp-tag(:class='giveaway.tagClass' data-test='status-tag') {{ t(`home.giveaways.table.status.${giveaway.status}`) }}
            td
              code {{ giveaway.startDate.toHuman() }}
            td
              code {{ giveaway.endDate.toHuman() }}
            td {{ giveaway.questions.length }}
            td {{ giveaway.numberOfParticipations }}
            td
              span(v-if='giveaway.partner' data-test='partner') {{ giveaway.partner.name }}
              span.fp-text.-color-shade-400(v-else data-test='no-partner') {{ t('home.giveaways.noPartner') }}
            td
              DropdownVue(:name='`actions-giveaways-${giveaway.id}`')
                DropdownItemVue(v-if='giveaway.status !== "SCHEDULED"' :id='\'giveawayId\'' :text='t(\'home.giveaways.actions.results\')' icon='piechart' :routeLocation='{ name: \'giveawayResults\', params: { clubSlug: clubSlug, giveawayId: giveaway.id  } }' :data-test='`giveaway-results-${giveaway.id}`')
                DropdownItemVue(:text='t(\'home.giveaways.actions.clone\')' icon='copy' :routeLocation='{ name: \'createGiveaway\', params: { clubSlug: clubSlug }, query: { from: `${clubSlug}/${giveaway.id}`} }' :data-test='`clone-giveaway-${giveaway.id}`')
                DropdownItemVue(v-if='giveaway.status !== "CLOSED" && giveaway.status !== "TO_CLOSE"' :text='t(\'home.giveaways.actions.update\')' icon='edit' :routeLocation='{ name: \'updateGiveaway\', params: { clubSlug: clubSlug, giveawayId: giveaway.id  } }' :data-test='`edit-giveaway-${giveaway.id}`')
                template(v-if='giveaway.status === "ACTIVE"')
                  DropdownSeparatorVue
                  DropdownItemVue(:href='giveaway.url' :text='t(\'home.giveaways.actions.see\')' icon='arrow-square-in' :data-test='`see-giveaway-${giveaway.id}`')
