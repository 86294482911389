PageVue
  .fp-vertical-spacing.-s-lg(v-if='!authorizations.isLoading()')
    .fp-vertical-spacing--line
      GlobalStatisticsVue(:show-statistics='hasAuthorization("clubs-read-orders")')
    template(v-if='hasAuthorization("clubs-read-orders") && !club.isOffChain')
      .fp-separator.-secondary
      .fp-vertical-spacing--line
        .fp-horizontal-spacing.-s-sm.-items-center
          .fp-horizontal-spacing--column
            i.fp-icon.fp-glyph-lock-open.-s18
          .fp-horizontal-spacing--column
            h2.fp-title.-color-shade-700 {{ t('home.paymentLimits.title') }}
      .fp-vertical-spacing--line
        PaymentTypesLimitVue(:club-slug='selectedClub')
    template(v-if='hasAuthorization("clubs-read-orders")')
      .fp-separator.-secondary
      .fp-vertical-spacing--line
        .fp-horizontal-spacing.-s-sm.-items-center
          .fp-horizontal-spacing--column
            i.fp-icon.fp-glyph-shopping-cart.-s18
          .fp-horizontal-spacing--column
            h2.fp-title.-color-shade-700
              span(v-if='club.isMarketplace' data-test='orders-title') {{ t('home.orders.title') }}
              span(v-else data-test='orders-title-foundation') {{ t('home.orders.titleFoundation') }}
      .fp-vertical-spacing--line
        OrdersTableVue(v-if='club.isOffChain')
        KriptownOrdersTableVue(v-else)
    template(v-if='hasAuthorization("clubs-read-donations") && club.isFoundation')
      .fp-separator.-secondary
      .fp-vertical-spacing--line
        .fp-horizontal-spacing.-s-sm.-items-center
          .fp-horizontal-spacing--column
            i.fp-icon.fp-glyph-hand-heart.-s18
          .fp-horizontal-spacing--column
            h2.fp-title.-color-shade-700 {{ t('home.donations.title') }}
      .fp-vertical-spacing--line
        DonationsTableVue
    template(v-if='hasAuthorization("clubs-read-coins-purchases") && !club.isOffChain')
      .fp-separator.-secondary
      .fp-vertical-spacing--line
        .fp-horizontal-spacing.-s-sm.-items-center
          .fp-horizontal-spacing--column
            i.fp-icon.fp-glyph-coins.-s18
          .fp-horizontal-spacing--column
            h2.fp-title.-color-shade-700 {{ t('home.coinsPurchase.title') }}
      .fp-vertical-spacing--line
        CoinsPurchasesTableVue
    template(v-if='hasAuthorization("clubs-read-coins-creations") && !club.isOffChain')
      .fp-separator.-secondary
      .fp-vertical-spacing--line
        .fp-horizontal-spacing.-s-sm.-items-center
          .fp-horizontal-spacing--column
            i.fp-icon.fp-glyph-thunder.-s18
          .fp-horizontal-spacing--column
            h2.fp-title.-color-shade-700 {{ t('home.coinsCreations.title') }}
      .fp-vertical-spacing--line
        CoinsCreationsTableVue
    template(v-if='hasAuthorization("clubs-read-coins-sale-orders") && !club.isOffChain')
      .fp-separator.-secondary
      .fp-vertical-spacing--line
        .fp-horizontal-spacing.-s-sm.-items-center
          .fp-horizontal-spacing--column
            i.fp-icon.fp-glyph-euro.-s18
          .fp-horizontal-spacing--column
            h2.fp-title.-color-shade-700 {{ t('home.coinsSaleOrders.title') }}
      .fp-vertical-spacing--line
        CoinsSaleOrdersTableVue
