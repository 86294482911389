include ../../club/loading-table.mixin.pug

template(v-if='coinsPurchases.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasCoinsPurchase' data-test='no-coins-purchases') {{ t('home.coinsPurchase.noCoinsPurchases') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='coins-purchases-table')
        thead
          tr
            th {{ t('home.table.date') }}
            th {{ t('home.table.fan') }}
            th {{ t('home.coinsPurchase.table.totalCost') }}
            th {{ t('home.coinsPurchase.table.tokensAmount') }}
        tbody
          tr(v-for='(coinsPurchase, index) in coinsPurchases.value().coinsPurchases' :key='index' :data-test='`coins-purchase-${index}`')
            td
              code {{ coinsPurchase.date.toHuman() }}
            td {{ coinsPurchase.fan.email }}
            td {{ coinsPurchase.cost.totalCost.text }}
            td {{ coinsPurchase.cost.coins.text }}
    .fp-vertical-spacing--line
      .fp-alert.-info
        i.fp-icon.fp-glyph-info.-color-info.-s18
        span {{ t('home.coinsPurchase.totalAmount') }}
        ExclusivePriceVue(:pricing='coinsPurchases.value().totalTokens' data-test='total-coins-purchases')
