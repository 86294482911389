import { Referral } from '@/staff/domain/fan/Referral';
import { Optional } from '@/common/domain/Optional';

export interface BackendReferral {
  id: string;
  bypassDueDiligence: boolean;
  creationDate: string;
  referrerId?: string;
}

export const toReferral = (backendReferral: BackendReferral): Referral => ({
  id: backendReferral.id,
  bypassDueDiligence: backendReferral.bypassDueDiligence,
  creationDate: new Date(backendReferral.creationDate),
  referrerId: Optional.ofEmpty(backendReferral.referrerId),
});
