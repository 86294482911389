import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { ExclusivesTableVue } from '@/staff/primary/exclusive/exclusives-table';
import { PageVue } from '@/staff/primary/club-layout/page';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { inject } from 'vue';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';

export default {
  name: 'ExclusivesPage',

  components: {
    PageVue,
    FairplayerButtonVue,
    ExclusivesTableVue,
  },

  props: {
    clubSlug: {
      type: String,
      required: true,
    },
  },

  setup() {
    const { t } = useI18n();
    const clubRepository = inject(clubRepositoryKey)!;

    const club = fromClub(clubRepository.getCurrentClub());

    return {
      t,
      club,
    };
  },
};
