import { authenticationKey } from '@/common/domain/auth/Authentication';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import { CoinsCreationsTableVue } from '@/staff/primary/club/coins-creations-table';
import { CoinsSaleOrdersTableVue } from '@/staff/primary/club/coins-sale-orders-table';
import { CoinsGiftsTableVue } from '@/staff/primary/club/coins-gifts-table';
import { GlobalStatisticsVue } from '@/staff/primary/club/statistics/global-statistics';
import { ExclusivesTableVue } from '@/staff/primary/exclusive/exclusives-table';
import { OrdersTableVue } from '@/staff/primary/order/orders-table';
import { CoinsPurchasesTableVue } from '@/staff/primary/wallet/coins-purchases-table';
import { defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FairplayerButtonVue } from '@/common/primary/button';
import { GiveawaysTableVue } from '@/staff/primary/giveaway/giveaways-table';
import { PaymentTypesLimitVue } from '@/staff/primary/club/payment-type-limit';
import { CoinsHoldingsTableVue } from '@/staff/primary/club/coins-holdings-table';
import { PageVue } from '@/staff/primary/club-layout/page';
import { Loader } from '@/common/primary/loader/Loader';
import { stafferRepositoryKey } from '@/staff/domain/staffer/StafferRepository';
import { fromClub } from '@/staff/primary/club/Club.ui';
import { KriptownOrdersTableVue } from '@/staff/primary/order/kriptown-orders-table';
import { DonationsTableVue } from '@/staff/primary/donation/donations-table';

export default defineComponent({
  name: 'ClubOverview',

  components: {
    CoinsPurchasesTableVue,
    CoinsCreationsTableVue,
    CoinsSaleOrdersTableVue,
    CoinsGiftsTableVue,
    CoinsHoldingsTableVue,
    DonationsTableVue,
    ExclusivesTableVue,
    GlobalStatisticsVue,
    KriptownOrdersTableVue,
    OrdersTableVue,
    GiveawaysTableVue,
    FairplayerButtonVue,
    PaymentTypesLimitVue,
    PageVue,
  },

  setup() {
    const { t } = useI18n();
    const authentication = inject(authenticationKey)!;
    const clubRepository = inject(clubRepositoryKey)!;
    const stafferRepository = inject(stafferRepositoryKey)!;

    const selectedClub = ref(clubRepository.getCurrentClub().slug);
    const authorizations = ref(Loader.loading<string[]>());

    const club = fromClub(clubRepository.getCurrentClub());

    const retrieveAuthorizations = async () => {
      const stafferIdentity = await authentication.authenticatedStaffer();
      stafferRepository
        .getAuthorizationsFor(stafferIdentity.username, selectedClub.value)
        .then(retrievedAuthorizations => {
          authorizations.value.loaded(retrievedAuthorizations);
        })
        .catch(() => {
          authorizations.value.loaded([]);
        });
    };

    onMounted(() => retrieveAuthorizations());

    const hasAuthorization = (authorization: string) => authorizations.value.value().includes(authorization);

    return {
      club,
      authorizations,
      hasAuthorization,
      selectedClub,
      t,
    };
  },
});
