import { CoinsCreation } from '@/staff/domain/club/CoinsCreation';
import { fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';
import { DateUi } from '@/common/primary/date/Date.ui';

export interface CoinsCreationUi {
  amount: TokensUi;
  date: DateUi;
}

export const fromCoinsCreation = (coinsCreation: CoinsCreation): CoinsCreationUi => ({
  amount: fromTokens(coinsCreation.coins),
  date: new DateUi(coinsCreation.date),
});
