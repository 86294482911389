import { BackendPrice, toTokens } from '@/common/secondary/token/BackendPrice';
import { BackendOldFan, toOldFan } from '@/staff/secondary/old-fan/BackendOldFan';
import { CoinsPurchase } from '@/staff/domain/wallet/CoinsPurchase';

export interface BackendCoinsPurchase {
  id: string;
  clubSlug: string;
  cost: BackendPrice;
  fan: BackendOldFan;
  date: string;
}

export const toCoinsPurchase = (backendCoinsPurchase: BackendCoinsPurchase): CoinsPurchase => ({
  id: backendCoinsPurchase.id,
  clubSlug: backendCoinsPurchase.clubSlug,
  cost: toTokens(backendCoinsPurchase.cost),
  fan: toOldFan(backendCoinsPurchase.fan),
  date: new Date(backendCoinsPurchase.date),
});

export const sortCoinsPurchaseByDateDesc = (coinsPurchase1: CoinsPurchase, coinsPurchase2: CoinsPurchase) =>
  coinsPurchase2.date.getTime() - coinsPurchase1.date.getTime();
