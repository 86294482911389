import { PaymentTypeAvailability } from '@/staff/domain/club/PaymentTypeAvailability';
import { emptyTokensUi, fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface PaymentTypeAvailabilityUi {
  accepted: boolean;
  limit: TokensUi;
}

export const fromPaymentTypeAvailability = (paymentTypeAvailability: PaymentTypeAvailability): PaymentTypeAvailabilityUi => ({
  accepted: paymentTypeAvailability.accepted,
  limit: fromTokens(paymentTypeAvailability.limit),
});

export const paymentTypeUnavailable = (): PaymentTypeAvailabilityUi => ({
  accepted: false,
  limit: emptyTokensUi(),
});
