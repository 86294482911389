include ../loading-table.mixin.pug

template(v-if='coinsCreations.isLoading()')
  +loading-table
template(v-else)
  p(v-if='!hasCoinsCreations' data-test='no-coins-creations') {{ t('home.coinsCreations.noCoinsCreations') }}
  .fp-vertical-spacing(v-else)
    .fp-vertical-spacing--line
      table.fp-table(data-test='coins-creations-table')
        thead
          tr
            th {{ t('home.table.date') }}
            th {{ t('home.coinsCreations.table.totalCost') }}
            th {{ t('home.coinsCreations.table.tokensAmount') }}
        tbody
          tr(v-for='(coinsCreation, index) in coinsCreations.value().coinsCreations' :key='index' :data-test='`coins-creation-${index}`')
            td
              code {{ coinsCreation.date.toHuman() }}
            td {{ coinsCreation.amount.totalCost.text }}
            td {{ coinsCreation.amount.coins.text }}
    .fp-vertical-spacing--line
      .fp-alert.-info
        i.fp-icon.fp-glyph-info.-color-info.-s18
        span.fp-text {{ t('home.coinsCreations.totalAmount') }}
        ExclusivePriceVue(:pricing='coinsCreations.value().totalTokens' data-test='total-coins-creations')
