import { Loader } from '@/common/primary/loader/Loader';
import { clubRepositoryKey } from '@/staff/domain/club/ClubRepository';
import {
  CoinsSaleOrdersUi,
  emptyCoinsSaleOrders,
  fromCoinsSaleOrders,
} from '@/staff/primary/club/coins-sale-orders-table/CoinsSaleOrders.ui';
import { computed, defineComponent, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { loggerKey } from '@/common/domain/Logger';
import { ExclusivePriceVue } from '@/staff/primary/exclusive/exclusive-price';

export default defineComponent({
  name: 'CoinsSaleOrderTable',

  components: { ExclusivePriceVue },

  setup() {
    const { t } = useI18n();
    const logger = inject(loggerKey)!;
    const clubRepository = inject(clubRepositoryKey)!;

    const coinsSaleOrders = ref(Loader.loading<CoinsSaleOrdersUi>());
    const hasCoinsSaleOrders = computed(
      () => !coinsSaleOrders.value.isLoading() && coinsSaleOrders.value.value().coinsSaleOrders.length > 0
    );

    const club = clubRepository.getCurrentClub();

    onMounted(() => listCoinsSaleOrders());

    const listCoinsSaleOrders = () => {
      clubRepository
        .getCoinsSaleOrders(club.slug, club.coin)
        .then(clubRepository => coinsSaleOrders.value.loaded(fromCoinsSaleOrders(clubRepository)))
        .catch(error => {
          coinsSaleOrders.value.loaded(emptyCoinsSaleOrders());
          logger.error('Failed to retrieve coinsSaleOrders', error);
        });
    };

    return {
      coinsSaleOrders,
      hasCoinsSaleOrders,
      t,
    };
  },
});
