import { CoinsCreations } from '@/staff/domain/club/CoinsCreations';
import { CoinsCreationUi, fromCoinsCreation } from '@/staff/primary/club/coins-creations-table/CoinsCreation.ui';
import { emptyTokensUi, fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface CoinsCreationsUi {
  coinsCreations: CoinsCreationUi[];
  totalTokens: TokensUi;
}

export const fromCoinsCreations = (coinsCreations: CoinsCreations): CoinsCreationsUi => ({
  coinsCreations: coinsCreations.creations.map(fromCoinsCreation),
  totalTokens: fromTokens(coinsCreations.totalTokens()),
});

export const emptyCoinsCreations = (): CoinsCreationsUi => ({
  coinsCreations: [],
  totalTokens: emptyTokensUi(),
});
