import { BackendCaller } from '@/common/secondary/BackendCaller';
import { ClubSlug } from '@/staff/domain/club/ClubSlug';
import { CoinsPurchaseRepository } from '@/staff/domain/wallet/CoinsPurchaseRepository';
import { CoinsPurchases } from '@/staff/domain/wallet/CoinsPurchases';
import { BackendCoinsPurchase, sortCoinsPurchaseByDateDesc, toCoinsPurchase } from '@/staff/secondary/wallet/BackendCoinsPurchase';
import { Coin } from '@/common/domain/token/Coin';

export class BackendCoinsPurchaseRepository implements CoinsPurchaseRepository {
  constructor(private backendCaller: BackendCaller) {}

  getByClub(clubSlug: ClubSlug, coin: Coin): Promise<CoinsPurchases> {
    return this.backendCaller.get<BackendCoinsPurchase[]>(`api/clubs/${clubSlug}/coins-purchases`).then(coinsPurchases => {
      return new CoinsPurchases(coinsPurchases.data.map(toCoinsPurchase).sort(sortCoinsPurchaseByDateDesc), coin);
    });
  }
}
