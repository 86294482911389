import { Orders } from '@/staff/domain/order/Orders';
import { fromOrder, OrderUi } from '@/staff/primary/order/Order.ui';
import { emptyTokensUi, fromTokens, TokensUi } from '@/common/primary/token/Tokens.ui';

export interface OrdersUi {
  totalTokens: TokensUi;
  totalUsedCoins: TokensUi;
  totalFinalCosts: TokensUi;
  totalFiatTokens: TokensUi;
  totalCoinsTokens: TokensUi;
  orders: OrderUi[];
}

export const fromOrders = (orders: Orders): OrdersUi => ({
  orders: orders.orders.map(fromOrder),
  totalTokens: fromTokens(orders.totalTokens()),
  totalUsedCoins: fromTokens(orders.totalUsedCoins()),
  totalFinalCosts: fromTokens(orders.totalFinalCosts()),
  totalFiatTokens: fromTokens(orders.totalFiatTokens()),
  totalCoinsTokens: fromTokens(orders.totalCoinsTokens()),
});

export const emptyOrders = (): OrdersUi => ({
  totalTokens: emptyTokensUi(),
  totalUsedCoins: emptyTokensUi(),
  totalFinalCosts: emptyTokensUi(),
  totalFiatTokens: emptyTokensUi(),
  totalCoinsTokens: emptyTokensUi(),
  orders: [],
});
